import { Box, chakra, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { TButtonWrapper, TSummaryItem } from './SWBasket.types';

export const BasketWrapper = styled(Flex)``;

export const WrapHeading = styled(Flex)`
  display: flex;
  align-items: center;
  > h2,
  > p {
    color: var(--mineShaft);
  }

  > h2[class*='Headingstyle__Heading-sc'] {
    font-size: ${rem('30px')};
    letter-spacing: ${rem('-0.3px')};
    line-height: ${rem('35px')};
    margin: 0 auto;

    @media (min-width: ${rem('992px')}) {
      font-size: ${rem('40px')};
      line-height: ${rem('48px')};
      margin: 0 auto;
    }
  }
`;

export const WrapSummary = styled(Flex)`
  h2,
  h4,
  p {
    color: var(--mineShaft);
  }

  > h2[class*='Headingstyle__Heading-sc'] {
    letter-spacing: ${rem('1px')};
    line-height: ${rem('30px')};
    margin-bottom: ${rem('33px')};

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('28px')};
    }
  }
`;

export const SummaryItem = styled.div<TSummaryItem>`
  width: 100%;
  [class*='ContainerWithLabelstyle__ContainerWithLabel-sc'] {
    width: 100%;
    > [class*='Containerstyle__Container-sc'] {
      padding: ${rem('20px')};

      @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        padding: ${rem('20px')} ${rem('20px')} ${rem('15px')};
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        padding: ${rem('18px')} ${rem('18px')} ${rem('5px')};
      }

      &:after {
        border: none;
      }
    }
  }

  [class*='ContainerWithLabelstyle__Header-sc'] {
    position: absolute;
    right: 0;
    z-index: 2;

    &,
    &:before {
      height: ${rem('34px')};
    }

    &:before {
      display: flex;
      content: '';
      position: relative;
      border-left: ${rem('18px')} solid transparent;
      border-right: 0 solid transparent;
      border-top: ${rem('34px')} solid
        ${({ productType, theme }) => {
          switch (productType) {
            case 'Bundle': {
              return theme.color.focus.default;
            }
            case 'Service Extra': {
              return '#B04A98';
            }
            case 'Add-on': {
              return '#EB9700';
            }
            default: {
              return 'transparent';
            }
          }
        }};
    }
  }

  [class*='ContainerWithLabelstyle__LabelContainer-sc'] {
    background: ${({ productType, theme }) => {
      switch (productType) {
        case 'Bundle': {
          return theme.color.focus.default;
        }
        case 'Service Extra': {
          return '#B04A98';
        }
        case 'Add-on': {
          return '#EB9700';
        }
        default: {
          return 'transparent';
        }
      }
    }};
    border-radius: 0;
    margin-right: 0;
    padding: 0;
    width: ${rem('92px')};
    display: flex;
    justify-content: center;

    p {
      text-align: center;
      font-size: ${rem('16px')};
      line-height: ${rem('18px')};
      font-weight: 400;
      color: ${({ theme }) => theme.color.primary2.default};
    }
  }
`;

export const AccordionItemContents = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: row;
    justify-content: space-between;
  }

  picture {
    display: flex;
    height: ${rem('87px')};
    max-width: ${rem('87px')};
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      height: ${rem('50px')};
      max-width: ${rem('50px')};
    }

    * {
      height: auto;
    }
  }
`;

export const EmptyBasketContainer = styled.div`
  display: flex;
  background: var(--white);
  width: 100%;
  height: ${rem('208px')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${rem('35px')} ${rem('0px')};
  margin: ${rem('40px')} ${rem('0px')};
`;

export const EmptyBasketDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const EmptyBasketTitle = styled.p`
  color: #333;
  font-size: ${rem('28px')};
  font-weight: bold;
  letter-spacing: 0;
  margin: ${rem('3px')} 0;
`;
export const EmptyBasketSubtitle = styled.p`
  color: #333;
  font-size: ${rem('18px')};
  letter-spacing: 0;
  margin: ${rem('3px')} 0;
`;

export const ItemTitleWrapper = styled(Flex)`
  text-align: left;
  padding: ${rem('20px')} 0 0 ${rem('20px')};
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 0 0 ${rem('20px')};
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${rem('14px')} 0 0 ${rem('20px')};
  }

  h4[class*='Headingstyle__Heading-sc'] {
    font-size: ${rem('24px')};
    letter-spacing: ${rem('0.8px')};
    line-height: ${rem('26px')};
    margin-bottom: ${rem('6px')};

    @media (min-width: ${rem('768px')}) {
      font-size: ${rem('28px')};
    }
  }
`;

export const GridContainer = styled(SimpleGrid)`
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: ${rem('16px')} ${rem('20px')};
  margin-top: ${rem('25px')};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: minmax(145px, 1fr) minmax(145px, 1fr) minmax(145px, 1fr) minmax(145px, 1fr);
    margin-top: ${rem('-25px')};
    margin-left: ${rem('108px')};
    overflow: hidden;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: repeat(4, minmax(145px, 1fr));

    margin-top: 0;
    margin-left: 0;
  }
`;

export const SItem = styled(Stack)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    &:nth-of-type(2) {
      justify-self: flex-end;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    &:nth-of-type(1) {
      order: 1;
    }
    &:nth-of-type(2) {
      order: 2;
    }
    &:nth-of-type(3) {
      align-items: center;
      order: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
          theme.breakpoints.lg - 1}px) {
        align-items: flex-start;
      }
    }
    &:nth-of-type(4) {
      order: 3;
    }
  }
`;

export const SItemHeader = styled(Box)``;

export const SPriceBig = styled(chakra.big)`
  font-size: ${({ size }) => (size ? size : rem('32px'))};
  line-height: ${rem('36px')};
`;

export const QuantityActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: ${rem('4px')};

  & > *:not(style) ~ *:not(style) {
    margin: 0 0 0 ${rem('24px')};
  }

  [class*='IconButtonstyle__IconButton-sc'] {
    background: #f4f4f4;

    &[aria-disabled='true'] {
      [class*='Iconstyle__Icon-sc'] * {
        stroke: #bdbbbb;
      }
    }

    [class*='Iconstyle__Icon-sc'] * {
      stroke: #007c92;
    }

    svg {
      width: ${rem('24px')};
      height: ${rem('24px')};
    }
  }
`;

export const SQuantityNumber = styled.span`
  font-size: ${rem('20px')};
`;

export const DeleteItemWrapper = styled(Box)``;

export const DeleteItemButton = styled(Flex)`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    justify-content: flex-start;
  }

  [class*='IconButtonstyle__IconButton-sc'] {
    width: ${rem('50px')};
    height: ${rem('50px')};
    align-items: center !important;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      border: none;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
      align-items: flex-end;
    }

    svg {
      width: ${rem('24px')};
      height: ${rem('24px')};
    }
  }
`;

export const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TotalInner = styled(Flex)`
  margin-right: 17%;

  @media (max-width: ${rem('768px')}) {
    margin-right: 0;
  }

  @media (min-width: ${rem('768px')}) and (max-width: ${rem('992px')}) {
    margin-right: 10%;
  }

  hr {
    height: ${rem('66px')};
  }
`;

export const TotalInnerItem = styled(Flex)`
  flex-direction: column;
`;

export const ButtonsWrapper = styled(Stack)`
  > div:nth-of-type(2) {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      max-width: ${rem('229px')};
      width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      max-width: ${rem('281px')};
    }
  }
`;

export const ButtonsInnerOne = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
  }

  & > *:not(style) ~ *:not(style) {
    margin: ${rem('16px')} 0 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin: 0 0 0 ${rem('16px')};
    }
  }

  > div:nth-of-type(1),
  div:nth-of-type(2) {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      width: ${rem('180px')};
    }
  }
`;

export const ButtonWrapper = styled.div<TButtonWrapper>`
  [class*='Buttonstyle__Button-sc'] {
    width: 100%;
    padding: ${rem('16px')} 0;

    ${({ appearance }) => {
      if (appearance === 'secondary') {
        return 'background: #666;';
      }
    }};
    font-size: ${rem('20px')};
    line-height: ${rem('24px')};
    height: ${rem('50px')};
  }
`;
